'use strict'

//import $ from "jquery";
import jQuery from "jquery";

//import moduleInit from './utils/module-init'

// global
window.jQuery = $;
window.$ = $;
 
